import {cart as cartStore} from "@/store/Cart";
import {observer} from "mobx-react";
import {useEffect} from "react";

const CartQty = observer(() => {
  useEffect(() => {
    cartStore.reloadCart();
  }, []);

  return cartStore.itemCount ? (
    <div className="absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center rounded-full bg-deep-green text-[10px] text-white">
      {cartStore.itemCount}
    </div>
  ) : (
    <></>
  );
});

export default CartQty;
